import * as React from "react"

import { Link, Flex, Text } from "@chakra-ui/react"
import { snapshotBaseURI } from "@app/graphql"

export interface TermsAndConditionsProps {
  actionText: string
}

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
  actionText,
}) => {
  return (
    <Flex direction="column" align="center" justify="center">
      <Text textAlign="center" fontSize={14} paddingTop={4}>
        {`By ${actionText} you agree to Kaspa's`}
      </Text>

      <Text fontSize={14} paddingBottom={2}>
        <Link href={`${snapshotBaseURI}/privacy-policy`} isExternal>
          <Text as="span" color="blue.500">
            Privacy Policy
          </Text>
        </Link>{" "}
        and{" "}
        <Link href={`${snapshotBaseURI}/terms`} isExternal>
          <Text as="span" color="blue.500">
            Terms of Use
          </Text>
        </Link>
      </Text>
    </Flex>
  )
}
